<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full">
          <div class="sm:text-center lg:text-left">
            <h1
              class="
                pt-5
                text-4xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-5xl
                md:text-6xl
              "
            >
              <span class="block xl:inline">{{ partnerName }}</span>
            </h1>
            <p
              class="
                text-base text-gray-500
                sm:mt-5 sm:text-lg
                w-full
                md:mt-5 md:text-xl
                lg:mx-0 lg:text-4xl
              "
            >
              {{ partnerBio }}
            </p>
          </div>
        </main>
      </div>
    </div>

    <div class="mx-auto max-w-6xl pl-2 lg:pl-0 md:max-w-xl lg:max-w-6xl">
      <h2
        class="
          text-3xl
          md:text-5xl
          mb-5
          font-extrabold
          tracking-tight
          sm:text-4xl
        "
      >
        {{ headerText }}
      </h2>

      <ul
        class="
          flex flex-col
          gap-6
          sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 sm:space-y-0
          lg:grid-cols-4 lg:gap-x-8
          justify-between
          mb-16
        "
      >
        <li v-for="partner in partners" :key="partner.name">
          <div>
            <div class="space-y-2">
              <div class="text-lg leading-6 font-medium space-y-1">
                <h3 class="text-gray-900 font-bold">{{ partner.name }}</h3>
                <p class="text-blue-700" v-if="partner.institution">
                  {{ `${partner.institution}` }}
                </p>
                <p
                  class="text-gray-700"
                  v-if="partner.title && !partner.multipleTitles"
                >
                  {{ `${partner.title}` }}
                </p>
                <p v-else>
                  <span
                    class="inline-block"
                    v-for="title in partner.title"
                    :key="title.path"
                    >{{ title }}</span
                  >
                </p>
              </div>
              <ul class="flex space-x-5"></ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerPage",
  props: ["partnerName", "partners", "partnerBio", "headerText"],
  components: {},
  methods: {
    getImgUrl(img) {
      const images = require.context("../assets/", true);
      return images("./" + img + "");
    },
  },
};
</script>
